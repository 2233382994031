.card-icon {
  float: right;
}
.ui.segment.hero-segment,
.ui.segment.info-segment,
.ui.segment.pages-segment,
.ui.segment.projects-segment,
.ui.segment.social-segment {
  border: none;
}
.ui.segment.hero-segment h4.with-div,
.ui.segment.info-segment h4.with-div,
.ui.segment.pages-segment h4.with-div,
.ui.segment.projects-segment h4.with-div,
.ui.segment.social-segment h4.with-div {
  margin: 2em 0;
  white-space: normal;
}
.ui.segment.social-segment h4.with-div {
  width: 100vw;
  text-align: center;
  font-size: 32px;
  line-height: 37px;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: #484848;
}
.ui.segment.social-segment h4.with-div:before,
.ui.segment.social-segment h4.with-div:after {
  content: none;
}
.ui.segment.social-segment .card {
  position: relative;
  overflow: hidden;
  padding-left: 10px;
  border: 1px solid #D1D1D1;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}
.ui.segment.social-segment .card:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.ui.segment.social-segment .card:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.ui.segment.social-segment .card .content {
  display: flex;
  align-items: center;
}
.ui.segment.social-segment .card .card-left {
  min-width: 50%;
}
.ui.segment.social-segment .card .card-right {
  min-width: 50%;
  transform: translateX(50%);
}
.ui.segment.social-segment .card .flip {
  transform: scaleX(-1);
}
.ui.segment.social-segment .card .header {
  font-size: 1.25em;
  line-height: 1.35em;
  color: #484848;
}
.ui.segment.social-segment .card .meta {
  font-weight: 300;
  font-size: 1em;
  line-height: 1.2em;
  color: #484848;
  margin-top: 0.2em;
}
.ui.segment.social-segment .card .card-icon {
  top: 0;
  right: 0;
}
.ui.segment.social-segment .card .card-icon > svg {
  width: 125%;
  height: 125%;
  fill: transparent;
  color: #CBCBCB;
}
.ui.segment.hero-segment {
  padding: 4.5em 0 1.5em;
}
.ui.segment.hero-segment h1 {
  font-size: 3.8em;
  font-weight: normal;
  margin-bottom: 0;
}
.ui.segment.hero-segment h2 {
  font-size: 1.3em;
  font-weight: normal;
  margin: 1.4em 0 1.2em;
  max-width: 30em;
}
.ui.segment.hero-segment .primary.button {
  font-size: 1.3em;
}
.asym-clip {
  background-color: #007CC4;
  width: 100%;
  height: 8em;
}
.ui.button.button-below-image {
  margin: 1.5em 0 0;
}
.ui.segment.info-segment {
  padding: 2em 0;
}
.ui.segment.pages-segment {
  padding: 4em 0;
}
.ui.segment.projects-segment {
  padding: 3em 0;
  background: #343434;
}
.ui.segment.projects-segment h4.with-div {
  width: 100vw;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.2em;
  color: #FFFFFF;
}
.ui.segment.projects-segment h4.with-div:before,
.ui.segment.projects-segment h4.with-div:after {
  content: none;
}
.ui.segment.projects-segment .card {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
}
.ui.segment.projects-segment .card .content {
  border-top: none;
}
.ui.segment.projects-segment .card .image,
.ui.segment.projects-segment .card .image > img {
  width: 280px;
  height: 280px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.ui.segment.projects-segment .card .user-picture {
  min-width: 130px;
  min-height: 130px;
  width: 130px;
  height: 130px;
  border: 8px solid #fff;
  border-radius: 50%;
  margin-left: -65px;
  z-index: 1;
}
.ui.segment.projects-segment .card .user-picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.ui.segment.projects-segment .card .header {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.ui.segment.projects-segment .card .description {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin-top: 1em;
  color: #000000;
}
.ui.segment.projects-segment .card .meta {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 1em;
}
.ui.segment.projects-segment .card .repo {
  font-size: 50px;
  width: 50px;
  height: 50px;
  color: #000;
  margin-left: 50px;
  margin-right: 50px;
}
.ui.segment.projects-segment .wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ui.segment.projects-segment .carousel__slider {
  width: 100%;
}
.ui.segment.projects-segment .carousel__back-button,
.ui.segment.projects-segment .carousel__next-button {
  margin: 60px;
  background: transparent;
  outline: none;
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: white;
  border: none;
}
.ui.segment.projects-segment .ui.cards > .card .meta,
.ui.segment.projects-segment .ui.card .meta {
  padding: 1em 0 0;
}
.ui.segment.projects-segment .ui.mini.buttons {
  margin-top: 50px;
}
.ui.segment.projects-segment .carousel__dot {
  background: transparent;
  color: #FFFFFF;
  opacity: 0.4;
  transition: 0.3s opacity;
}
.ui.segment.projects-segment button.carousel__dot--selected {
  opacity: 1 !important;
}
.ui.segment.social-segment {
  padding: 4em 0 8em;
}
/* Widescreen Monitor */
/* Large Monitor */
/* Computer / Desktop / iPad Landscape */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ui.segment.hero-segment h1 {
    font-size: 2.8em;
  }
  .ui.segment.hero-segment h2 {
    font-size: 1.2em;
  }
  .ui.segment.hero-segment .primary.button {
    font-size: 1.2em;
  }
}
/* Tablet / iPad Portrait Screens */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ui.segment.hero-segment h1 {
    font-size: 2.4em;
  }
  .ui.segment.hero-segment h2 {
    font-size: 1em;
  }
  .ui.segment.hero-segment .primary.button {
    font-size: 1em;
  }
}
/* Mobile Screens */
@media only screen and (max-width: 767px) {
  .ui.segment.hero-segment h1 {
    font-size: 2em;
  }
  .ui.segment.hero-segment h2 {
    font-size: 0.8em;
  }
  .ui.segment.hero-segment .primary.button {
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 910px) {
  .ui.segment.social-segment .card:nth-child(3n) {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .ui.segment.social-segment .card:last-child {
    border-radius: 25px;
  }
}
@media only screen and (max-width: 910px) and (min-width: 600px) {
  .ui.segment.social-segment .card:nth-child(odd) {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .ui.segment.social-segment .card:nth-child(even) {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .ui.segment.social-segment .card {
    border-radius: 25px;
  }
}
@media only screen and (max-width: 1440px) {
  .ui.segment.projects-segment .carousel__inner-slide .ui.container {
    display: flex;
    justify-content: center;
  }
  .ui.segment.projects-segment .card {
    width: 90%;
  }
}
@media only screen and (max-width: 1200px) {
  .ui.segment.projects-segment .carousel__back-button,
  .ui.segment.projects-segment .carousel__next-button {
    margin: 0 6% 3rem 6%;
  }
  .ui.segment.projects-segment .card {
    flex-direction: column;
    width: 55%;
  }
  .ui.segment.projects-segment .card .image,
  .ui.segment.projects-segment .card .image > img {
    width: 100%;
    height: 12rem;
    border-radius: 30px 30px 0 0;
  }
  .ui.segment.projects-segment .card .user-picture {
    position: relative;
    top: -3rem;
    margin: 0;
  }
  .ui.segment.projects-segment .card .content {
    padding: 0 1rem;
  }
  .ui.segment.projects-segment .card .repo {
    margin: 2rem 0 1rem;
  }
  .ui.segment.projects-segment .ui.mini.buttons {
    margin-top: 0;
  }
  .ui.segment.projects-segment .ui.center.aligned.container {
    position: relative;
    top: -1rem;
  }
  .ui.segment.projects-segment .carousel__slide {
    height: auto;
  }
  .ui.segment.projects-segment .carousel__inner-slide {
    position: static;
  }
}
@media only screen and (max-width: 900px) {
  .ui.segment.projects-segment .card {
    width: 75%;
  }
}
@media only screen and (max-width: 767px) {
  .ui.segment.projects-segment .carousel__back-button,
  .ui.segment.projects-segment .carousel__next-button {
    margin: 0 4% 3rem 4%;
  }
  .ui.segment.projects-segment .card {
    width: 90%;
  }
}
@media only screen and (max-width: 526px) {
  .ui.segment.projects-segment .carousel__back-button,
  .ui.segment.projects-segment .carousel__next-button {
    display: none;
  }
}
