.text.mini {
  font-size: 0.78571429rem;
}
.text.tiny {
  font-size: 0.85714286rem;
}
.text.small {
  font-size: 0.92857143rem;
}
.text.medium {
  font-size: 1rem;
}
.text.large {
  font-size: 1.14285714rem;
}
.text.big {
  font-size: 1.28571429rem;
}
.text.huge {
  font-size: 1.42857143rem;
}
.text.huge {
  font-size: 1.71428571rem;
}
.ui.padded.buttons .button,
.ui.padded.button {
  padding: 0.8em 2.5em;
}
.text.no-common-ligatures {
  font-variant: no-common-ligatures;
}
